<template>
  <div class="my">
    <div class="myTop">
        <p class="phone">{{userInfo.managerPhone}}</p>
        <p>您好，{{userInfo.managerName}}</p>
      <div>
      </div>
    </div>
    <div class="myCon">
      <ul>
        <li @click="toShare()">
          <span>邀请注册</span>
          <span><van-icon name="arrow" /></span>
        </li>
         <router-link :to="{path:'invitatRecord'}"> 
          <li>
            <span>邀请记录</span>
            <span><van-icon name="arrow" /></span>
          </li>
         </router-link>
      </ul>
    </div>
    <img src="../assets/img/shareBg.png" class="shareBg" v-show="shareBgShow" @click="toShare1()" />
  </div>
</template>
<script>

import {getUserManager,getWxConfig} from "../services/api";

export default {
  data() {
    return {
      userInfo:{managerPhone:'',managerName:''},
      shareBgShow:false,
      shareData:{title:'',desc:'',link:'',imgUrl:''}
    };
  },
  created: function() {
    this.getUserManager()

    this.getWxConfig()
  },
  methods: {
    async getUserManager() {
      const data = await getUserManager({});
      this.userInfo = data.data
    },
    async getWxConfig(){
      let url = location.href;
      url = url.split('#/')
      url = url[0]
      const data =await getWxConfig({ url});
      if (data.code === 1) {
        this.wxFun(data.data)
      }
    },
    wxFun(data){
      let _this = this
      // var jweixin = require('../utils/wxShare.js')
      var jweixin = require('jweixin-module')
      let  shareData = {
        'title':'注册邀请',
        'desc':'点击成为理财用户，多样化产品供您选择！',
        'link':this.userInfo.inviteUrl,
        'imgUrl':'https://oss.liangliangsz.tech/group1/default/20221020/16/53/4/logo.jpg'
      }
      _this.shareData = shareData
        jweixin.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature,// 必填，签名
          jsApiList: [
            'updateAppMessageShareData',
            'updateTimelineShareData' ,
            'onMenuShareAppMessage',  //旧接口，即将废弃
            'onMenuShareTimeline' ,
          ] // 必填，需要使用的JS接口列表
        });
        jweixin.checkJsApi({
          jsApiList: ['updateAppMessageShareData','updateTimelineShareData','onMenuShareAppMessage','onMenuShareTimeline'],
          // jsApiList: ['onMenuShareAppMessage','onMenuShareTimeline'],
          success: function(res) {
            console.log(res)
          }
        });
        jweixin.ready(function () {   //需在用户可能点击分享按钮前就先调用
          // console.log(_this.shareData)
          if(jweixin.updateAppMessageShareData){
            jweixin.updateAppMessageShareData({ //分享给朋友 || 分享到QQ   
              title : _this.shareData.title, // 分享标题
              desc : _this.shareData.desc, // 分享描述
              link : _this.shareData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
              imgUrl : _this.shareData.imgUrl, // 分享图标
              success: function () {
                _this.shareBgShow = false
              }
            })
            jweixin.updateTimelineShareData({ //分享到朋友圈 || 分享到 QQ 空间
              title : _this.shareData.title, // 分享标题
              link : _this.shareData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
              imgUrl : _this.shareData.imgUrl, // 分享图标
              success: function () {
                _this.shareBgShow = false
              }
            })
          }else{
            jweixin.onMenuShareAppMessage({ //分享给朋友 || 分享到QQ   
              title: _this.shareData.title, // 分享标题
              desc: _this.shareData.desc, // 分享描述
              link: _this.shareData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
              imgUrl: _this.shareData.imgUrl, // 分享图标
              success: function () {
                _this.shareBgShow = false
              }
            })
            jweixin.onMenuShareTimeline({ //分享到朋友圈 || 分享到 QQ 空间
              title: _this.shareData.title, // 分享标题
              link: _this.shareData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
              imgUrl: _this.shareData.imgUrl, // 分享图标
              success: function () {
                _this.shareBgShow = false
              }
            })
          }
          
        })
    },
    toShare(){
      this.shareBgShow = true
    },
    toShare1(){
      this.shareBgShow = false
    },
  }
};
</script>
<style lang="scss" scoped>
.my{
   .myTop{
      padding:3rem 2rem;
      background: linear-gradient(0deg, #fff, #0398FF);
      font-size:0.9rem;position: relative;
      p{line-height: 3rem;}
      p:first-child{font-size:1.4rem}

    }
    .myCon{
      ul{
        background: #fff;
        margin-top:1rem;
        a{color:#333;}
        li{
          display: flex;justify-content: space-between;
          padding:0 1rem;
          margin-bottom: -1px;
          border: 1px solid #e6e6e6;
          border-width: 1px 0;
          min-height: 1rem;
          align-items: center;
          height:3rem;
          line-height:3rem;
        }
      }
    }
    .shareBg{width:100%;position:fixed;top:0;left:0;}
}


 

</style>
